import LucidImage from '@/src/utils/LucidImage'
import React, {CSSProperties, useEffect, useState} from 'react'
import constructAssetUrl from '../../editor/shared/constructAssetUrl'
import styled from 'styled-components'
import type {Media} from '@einsteinindustries/tinacms-core'

interface SectionImageProps extends Omit<ImageWrapperProps, 'imageSize'> {
  name: string
  cms: boolean
  width: number
  height: number
  style?: CSSProperties
}

interface ImageWrapperProps {
  src: string
  alt: string
  link?: string
  imageSize: ImageSize | null
  className?: string
  priority: boolean | string
  sizes: string
}

interface ImageSize {
  width: number | string
  height: number | string
}

/**
 * Some other bug with Next.js.
 * Images with sizes specified to them will set a width and height of 0
 */
const ProgressiveImageFix = styled.div`
  span, div {
    position: unset!important;
    padding-top: unset!important;
  }
  img.progressive-image {
    width: 100%!important;
    height: unset!important;
    position: relative!important;
  }
`

export default function SectionImage({
  name,
  src,
  alt,
  width,
  height,
  link,
  cms,
  className,
  style,
  priority,
  sizes
}: SectionImageProps) {

  const [inlineImageComponent, setInlineImageComponent] = useState<JSX.Element | null>(null)

  useEffect(() => {
    const setInlineImageModule = async () => {
      const {InlineImage} = await import('@einsteinindustries/react-tinacms-inline')
      setInlineImageComponent(
        <InlineImage
          name={`${name}.src`}
          parse={(media: Media) => {
            if (!media) {
              return ''
            }
            return constructAssetUrl(media.id)
          }}
          previewSrc={(src: string) => src}
          focusRing={true}
          alt={alt}
          className={className}
        >
          {({src: loaded}: {src?: string}) => {
            return loaded ? (
              <ImageWrapper
                src={src}
                alt={alt}
                imageSize={{width, height}}
                className={className}
                priority={priority}
                sizes={sizes}
              />
            ) : null
          }}
        </InlineImage>
      )
    }
    if (cms) {
      setInlineImageModule()
    }
  }, [])

  return (
    <ProgressiveImageFix style={style}>
      {cms ? inlineImageComponent :
        <ImageWrapper
          src={src}
          alt={alt}
          link={link}
          imageSize={{width, height}}
          className={className}
          priority={priority}
          sizes={sizes}
        />
      }
    </ProgressiveImageFix>
  )
}

function ImageWrapper({src, alt, imageSize, link, priority, className, sizes}: ImageWrapperProps) {
  if (src) {
    const imgComponent = (
      <LucidImage
        src={src}
        alt={alt}
        width={imageSize?.width}
        height={imageSize?.height}
        className={className}
        priority={priority === 'true' || priority === true}
        sizes={sizes}
      />
    )
    return link && link !== '' ? <a href={link}>{imgComponent}</a> : imgComponent
  }
  return null
}
