import {createContext, PropsWithChildren, useReducer} from 'react'
import reducer, {ActionTypes} from './reducer'
import {BlockContents, Menu, Page, Site} from '@/components/shared/types'
import {StyleCoreSiteProvider} from '@/src/state/site/StyleCoreStore'
import {SiteThemeProvider} from '@/src/state/site/ThemeStore'
import {ColorSchemeResponse} from '@/components/managers/ColorSchemeManager/ColorScheme'

export type LucidState = {
  site_build_id: string
  menus: Menu[]
  pages: Page[]
  schemes: ColorSchemeResponse[]
  site_contents: BlockContents
  favicon: string
  isSaving: boolean
  site?: Site
  page_build_id?: string
  revalidateOnFocus: boolean
}

const initialState: LucidState = {
  site_build_id: '',
  menus: [],
  pages: [],
  schemes: [],
  site_contents: {},
  favicon: '/favicon.ico',
  isSaving: false,
  site: undefined,
  page_build_id: undefined,
  revalidateOnFocus: true
}

export type Dispatcher = (arg: {type: typeof ActionTypes[number], payload: Partial<LucidState>}) => void

type LucidContextInterface = readonly [LucidState, Dispatcher]

export const LucidSiteContext = createContext<LucidContextInterface>([initialState, () => {}])

export const LucidSiteProvider = ({children}: PropsWithChildren<any>) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  return (
    <LucidSiteContext.Provider value={[state as LucidState, dispatch]}>
      <StyleCoreSiteProvider>
        <SiteThemeProvider>
          {children}
        </SiteThemeProvider>
      </StyleCoreSiteProvider>
    </LucidSiteContext.Provider>
  )
}
