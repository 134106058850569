import type {TypographyElement} from '@/components/editor/typography/types'
import type {FieldConfig, FormConfig} from '@/components/shared/externalTypes'
import type {StyleCoreTarget, TinaFunctionalComponent} from '@/components/shared/StyleCore'
import Sections from '@/components/sections'

const SITE_DEFAULT_FORM_ID = 'site-default'

export const StyleCoreHelpers = {
  selectors: {
    get: (target: StyleCoreTarget) => {
      const targetClasses: string[] = [target.componentName]
      if (typeof target.identifier !== 'undefined') {
        targetClasses.push(`ST${target.identifier}`)
      }
      return targetClasses
    },
    getSelectorID: (target: StyleCoreTarget) => {
      return StyleCoreHelpers.selectors.get(target).join('-')
    },
    getTypographyBaseSelector: (target: TypographyElement) => {
      const className = target.name.replaceAll(' ', '-').toLowerCase()
      let additionalSelectors = target.selectors ? `${target.selectors.trimEnd()}` : ''
      if (target.selectors) {
        additionalSelectors = additionalSelectors.endsWith(',') ? additionalSelectors.slice(0, -1) : additionalSelectors
        additionalSelectors = additionalSelectors.split(',').map((selector) => {
          return ` {target} ${selector}`
        }).join(',')
        additionalSelectors = `, ${additionalSelectors}`
      }
      if (typographyClassToElementMap.has(className)) {
        return `{target} .${className}, {target} ${typographyClassToElementMap.get(className) as string}${additionalSelectors}`
      }
      return className + additionalSelectors
    }
  },
  formManager: {
    convertTargetToFormID: (target: StyleCoreTarget) => {
      const targetClasses = StyleCoreHelpers.selectors.get(target)
      return targetClasses.join('::')
    },
    convertFormIDToTarget: (formID: string) => {
      const targetClasses = formID.split('::')
      return {
        componentName: targetClasses[0],
        identifier: targetClasses[1]
      }
    }
  },
  sections: {
    getFormConfigForAllSections: (siteId: string, onSubmit: (values: any) => void): FormConfig => {
      const dynamicFields: { [key: string]: FieldConfig } = {}
      Object.entries(Sections as unknown as TinaFunctionalComponent<any>).forEach(([, value]) => {
        if (typeof value.StyleCore !== 'undefined' && typeof value.StyleCore.target?.componentName !== 'undefined') {
          dynamicFields[value.StyleCore.target.componentName] = {
            content: value.StyleCore.config
          } as FieldConfig
        }
      })
      return {
        id: SITE_DEFAULT_FORM_ID,
        title: 'Site Style',
        listeners: {
          preventContentTableUpsert: false,
          target: {
            site_id: siteId
          },
          onSubmit
        },
        content: {
          general: {
            content: {
              // global sitewide styles (no section specific)
              nothingHere: {}
            }
          },
          sections: {
            content: dynamicFields
          }
        }
      }
    }
  }
}

export const typographyClassToElementMap = new Map<string, string>([
  ['heading-1', 'h1'],
  ['heading-2', 'h2'],
  ['heading-3', 'h3'],
  ['heading-4', 'h4'],
  ['heading-5', 'h5'],
  ['heading-6', 'h6'],
  ['paragraph', 'p'],
  ['blockquote', 'blockquote'],
  ['list-item', 'li'],
  ['list', 'ul'],
  ['link', 'a'],
  ['label', 'label'],
  ['input', 'input'],
  ['button', 'button'],
])
